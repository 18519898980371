<template>
  <div class="page">
    <div class="search-box section">
      <el-form ref="query" :inline="true" :model="query" size="medium">
        <el-form-item prop="regionId">
          <el-cascader v-model="defaultRegion" :clearable="true" :filterable="true" :props="props"
                       :show-all-levels="false" placeholder="请选择区域"/>
        </el-form-item>
        <el-form-item prop="id">
          <Settlement v-model="query.id" class="width300"/>
        </el-form-item>
        <el-form-item prop="code">
          <el-input
              v-model="query.code"
              clearable
              class="width300"
              placeholder="请输入结算方编号"
          />
        </el-form-item>
        <el-form-item prop="subjectType">
          <el-select
              v-model="query.subjectType"
              clearable
              placeholder="请选择主体类型"
              class="width300"
          >
            <el-option label="企业" value="1"></el-option>
            <el-option label="个体户" value="2"></el-option>
            <el-option label="个人" value="3"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item prop="withdrawalStatus">
          <el-select
            placeholder="请选择提现状态"
            v-model="query.withdrawalStatus"
            clearable
            style="width: 300px"
          >
            <el-option value="1" label="待账户验证"></el-option>
            <el-option value="2" label="可提现"></el-option>
            <el-option value="3" label="不可提现"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item prop="certificationStatus">
          <el-select
              v-model="query.certificationStatus"
              clearable
              placeholder="请选择认证状态"
              class="width300"
          >
            <el-option label="已认证" value="1"></el-option>
            <el-option label="未认证" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!--信息统计框-->
    <div class="search-box section page-header height50 padding20-32">
      <div class="content" style="margin-left: 0;">
        <div class="content-item">
          <div class="title">信息条数：</div>
          <div class="text">{{ pagination.totalSize }}</div>
        </div>
      </div>
    </div>

    <div class="content-box section">
      <div class="row_new padding-bottom19">
        <el-button type="primary" size="small" @click="goAdd">新增</el-button>
      </div>
      <el-table
          v-loading="isLoading"
          :data="dataList"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column label="结算方编号" prop="code" width="240">
        </el-table-column>
        <el-table-column label="结算方名称" prop="name" width="240">
        </el-table-column>
        <el-table-column label="主体类型" prop="subjectType" width="100">
          <template slot-scope="scope">
            {{ subjectType[scope.row.subjectType] }}
          </template>
        </el-table-column>
        <el-table-column label="城区" prop="regionName" width="240">
          <!-- <template>山东省青岛市即墨区</template> -->
        </el-table-column>
        <el-table-column label="注册时间" prop="registrationTime" width="240">
        </el-table-column>
        <el-table-column label="经营车场数量" prop="parkingLotNum" width="160">
        </el-table-column>
        <el-table-column label="泊位数量" prop="berthNum" width="160">
        </el-table-column>
        <el-table-column label="结算账期(天)" prop="accountPeriod" width="160">
          <template slot-scope="scope">
            {{ scope.row.accountPeriod === -1 ? "" : scope.row.accountPeriod }}
          </template>
        </el-table-column>
        <el-table-column label="余额" prop="" width="160"></el-table-column>
        <!-- <el-table-column label="提现状态" prop="withdrawalStatus" width="240"> </el-table-column> -->
        <el-table-column
            label="认证状态"
            prop="certificationStatus"
            width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.certificationStatus === "1" ? "已认证" : "未认证" }}
          </template>
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作" width="280">
          <template slot-scope="scope">
            <el-button
                v-if="hasRights('001003005003')"
                class="common-text"
                size="small"
                style="font-size: 14px"
                type="text"
                @click="goDetail(scope.row.id)"
            >详情
            </el-button
            >
            <!-- <el-button
            type="text"
            size="small"
            style="font-size: 14px"
            class="common-text"
            @click="goEdit(scope.row.id)"
            >编辑</el-button
          > -->
            <!-- <el-button
            type="text"
            size="small"
            style="font-size: 14px"
            class="common-text"
            @click="goAuthentication(scope.row.id)"
            >认证管理</el-button
          > -->
            <el-button
                v-if="hasRights('001003005004')"
                class="common-text"
                size="small"
                style="font-size: 14px"
                type="text"
                @click="goRelation(scope.row.id)"
            >关联车场
            </el-button
            >
            <el-button
                v-if="hasRights('001003005005')"
                class="common-text"
                size="small"
                style="font-size: 14px"
                type="text"
                @click="goDelete(scope.row.id)"
            >删除
            </el-button
            >
            <el-button
                size="small"
                style="font-size: 14px"
                type="text"
                @click="cashout(scope.row)"
            >提现申请
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.page"
            :page-size="pagination.size"
            :page-sizes="[10, 15, 30, 50]"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getRegionList} from "@/api/user";
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
import Settlement from "@/components/Settlement";
import {deleteSettlementById, doCashOutApply, findSettlementInfoByPage,} from "@/api/settlementManage";
import {doExport} from "@/api/common";

export default {
  name: "settlement_manage",
  mixins: [BaseMixin, Permission],
  data() {
    return {
      defaultRegion: [],
      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const {level, value} = node;
          console.log('node.value:', value)
          let regionId = level === 0 ? 0 : value;
          getRegionList({regionId: regionId})
              .then(({returnObject}) => {
                console.log('getRegionList:', returnObject)
                const nodes = returnObject.map(item => ({
                      value: item.id,
                      label: item.name,
                      leaf: item.level == 3,
                    })
                )
                resolve(nodes)
              })
        }
      },
      query: {
        regionId: "", //城区
        id: "", //结算方id
        code: "", //结算方编号
        subjectType: "", //主体类型  1企业  2个体户  3个人
        withdrawalStatus: "", //提现状态  1待账户验证   2可提现  3不可提现
        certificationStatus: "", //认证状态   1已认证   2未认证
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      subjectType: {
        1: "企业",
        2: "个体户",
        3: "个人",
        "": "",
      },
      dataList: [],
    };
  },
  components: {
    Settlement,
  },
  methods: {
    //重置查询条件
    resetQueryForm() {
      this.$refs.query.resetFields();
      this.doSearch();
    },
    //查询
    doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    cashout(row) {
      console.log(row);
      this.$prompt("请填写提现金额", "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^(([0-9]|([1-9][0-9]{0,9}))((\\.[0-9]{1,2})?))$/,
        inputErrorMessage: "金额格式不正确",
      })
          .then(async ({value}) => {
            const res = await doCashOutApply({
              settlementUserId: row.id,
              amount: value,
            });

            console.log(res);
            if (res && res.code === 30) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            }
          })
          .catch(() => {
          });
    },
    async search() {
      this.isLoading = true;
      this.query.region = this.defaultRegion.length == 3 ? this.defaultRegion[2] : this.defaultRegion[1]
      const params = this.paramFormat(this.query);
      const res = await findSettlementInfoByPage(params);
      this.isLoading = false;
      if (res && res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        this.dataList = returnObject.list;
        this.pagination.totalSize = returnObject.total;
        this.pagination.totalPages = returnObject.pages;
        this.form.exportSize = returnObject.total;
      }
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      // window.open("http://172.16.69.129:81" + "/settlement/findSettlementInfoByPage?" + objectToQueryString(params));
      doExport(
          "/settlement/findSettlementInfoByPage",
          params,
          "结算方管理.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;
    },
    //添加
    goAdd() {
      this.$router.push("/user_manage/settlement_manage/add_settlement");
    },
    //详情
    goDetail(e) {
      this.$router.push({
        path: "/user_manage/settlement_manage/detail_settlement",
        query: {
          id: e,
        },
      });
    },
    //编辑
    goEdit(e) {
      this.$router.push({
        path: "/user_manage/settlement_manage/add_settlement",
        query: {
          id: e,
        },
      });
    },
    //关联车场
    goRelation(e) {
      this.$router.push({
        path: "/user_manage/settlement_manage/relation_parkinglot",
        query: {
          id: e,
        },
      });
    },
    //认证管理
    goAuthentication(e) {
      this.$router.push({
        path: "/user_manage/settlement_manage/authentication_manage",
        query: {
          id: e,
        },
      });
    },
    //删除结算方
    goDelete(e) {
      this.$confirm("确定要删除该结算方吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // const body = {
        //   id: e,
        // };
        this.isLoading = true;
        deleteSettlementById(e).then((res) => {
          setTimeout(() => {
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message.success("删除成功！");
              this.doSearch();
            }
          }, 2000);
        });
      });
    },
  },
  activated() {
    // const isSave = window.sessionStorage.getItem('save_success');
    const isSave = sessionStorage.getItem("save_success");
    if (isSave === "1") {
      // 刷新
      this.doSearch();
    }
  },
  mounted() {
    this.doSearch();
  },
};
</script>

<style lang="less" scoped>
</style>
